<template>
  <v-container fluid>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-form
      ref="logForm"
      v-model="valid"
      
    >
<v-row>
<!-- From Date Picker -->
<v-col>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
  <v-menu v-else v-model="menuFromDate" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field 
        :dark="$store.getters.getColorPalette().isDark"
        v-model="form.from_date"
        :label="$store.getters.getTextMap().from_date || 'From Date'"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="objectRules"
      ></v-text-field>
    </template>
    <v-date-picker 
      :dark="$store.getters.getColorPalette().isDark"
      v-model="form.from_date"
      @input="menuFromDate = false"
    ></v-date-picker>
  </v-menu>
</v-col>

<!-- From Time Picker -->
<v-col>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
  <v-menu v-if="!loading" ref="menufrom" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
    :return-value.sync="fromTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field  
        :dark="$store.getters.getColorPalette().isDark"
        v-model="form.fromTime"
        :label="$store.getters.getTextMap().from"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="objectRules"
      ></v-text-field>
    </template>
    <v-time-picker v-if="menu3" v-model="form.fromTime" full-width format="24hr" @click:minute="$refs.menufrom.save(form.fromTime)"></v-time-picker>
  </v-menu>
</v-col>

<!-- To Date Picker -->
<v-col>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
  <v-menu v-else v-model="menuToDate" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field  
        :dark="$store.getters.getColorPalette().isDark"
        v-model="form.to_date"
        :label="$store.getters.getTextMap().to_date || 'To Date'"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="objectRules"
      ></v-text-field>
    </template>
    <v-date-picker  
      :dark="$store.getters.getColorPalette().isDark"
      v-model="form.to_date"
      @input="menuToDate = false"
    ></v-date-picker>
  </v-menu>
</v-col>

<!-- To Time Picker -->
<v-col>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
  <v-menu v-if="!loading" ref="menuto" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
    :return-value.sync="toTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field  
        :dark="$store.getters.getColorPalette().isDark"
        v-model="form.toTime"
        :label="$store.getters.getTextMap().to"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="objectRules"
      ></v-text-field>
    </template>
    <v-time-picker v-if="menu2" v-model="form.toTime" full-width format="24hr" @click:minute="$refs.menuto.save(form.toTime)"></v-time-picker>
  </v-menu>
</v-col>

<!-- Submit Button -->
<v-col>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
  <v-btn v-if="!loading" ref="submitBtn" :color="$store.getters.getColorPalette().submitbtnColor"
     @click="onSubmit">
    {{ $store.getters.getTextMap().fetch_logs }}
  </v-btn>
</v-col>
</v-row>

      </v-form>
      <v-row>
                  <v-col cols="12">
                      <v-card  :dark="$store.getters.getColorPalette().isDark" >
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
         :dark="$store.getters.getColorPalette().isDark" 
        ></v-text-field>
      </v-card-title>
                                         
                      <v-data-table
        :headers="headers"
        :items="logs"
        item-key="index"
        :search="search"
        :single-expand="true"
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
      loading-text="Loading... Please wait"
      :dark="$store.getters.getColorPalette().isDark" 
      >
      <template v-slot:top>
        <v-toolbar
          flat
          :dark="$store.getters.getColorPalette().isDark" 
        >
          <v-toolbar-title >{{ $store.getters.getTextMap().trigger_logs }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item }}
        </td>
      </template>
            <template v-slot:item.param_id="{ item }">
        <td>
          {{ $store.getters.getParamById(item.param_id).name }}
        </td>
      </template>
            <template v-slot:item.trigger_id="{ item }">
        <td>
          {{ $store.getters.getTriggerById(item.trigger_id).name }}
        </td>
      </template>
                      </v-data-table>
                      </v-card>
                  </v-col>
              </v-row>
              
  
  </v-container>
      
  </template>

    <script>
    import axios from 'axios'
    import moment from 'moment'
    import InfoAlert from '@/components/InfoAlert'
    
    export default {
        name:'TriggerLogTable',
        props:['trigger_id'],
        components:{
            InfoAlert
        },
        mounted(){
            this.form.from_date=moment().subtract(1,'days').format('YYYY-MM-DD')
            this.form.to_date=moment().format('YYYY-MM-DD')
            this.form.toTime='23:59'
            this.form.fromTime='00:00'
            //let btn=this.$refs.submitBtn
            //btn.click()
            this.onSubmit()
        },
        data(){
            return {
                search:'',
                 valid:false,
                    menu:false,
                    menu2:false,
                    menu3:false,
                    menuFromDate: false, 
                    menuToDate:false,
                graphData:[],
                toTime:null,
                fromTime:null,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                expanded: [],
                logs:[],
                form:{
                    
                },
                headers: [
              {
                text: 'From',
                align: 'start',
                filterable: false,
                value: 'from_Timestamp',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              {
                text: 'To',
                align: 'start',
                filterable: false,
                value: 'to_Timestamp',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Trigger Name', value: 'trigger_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              // { text: 'Log Id', value: 'log_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Param', value: 'param_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Value', value: 'value', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              ],
            
                objectRules:[
                    v => !!v || 'Required',
                ],
            }
        },
        computed:{
            fromTimeStamp(){
            if(this.form.from_date && this.form.fromTime){
              
                let dt=this.form.from_date.toString()
                if(this.fromTime){
                  dt+=' '+this.fromTime.toString()
                  return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
              
            }else{
              return null
            }
          },
          toTimeStamp(){
            if(this.form.to_date){
              
                let dt=this.form.to_date.toString()
                if(this.toTime){
                  dt+=' '+this.toTime.toString()
                  //return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }else{
                    dt+=' 23:59'
                }
                //return Number(moment(dt,'YYYY-MM-DD').format("X"));
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              
            }else{
              return null
            }
          },
        },
        methods: {
            onSubmit(){
                this.$refs.logForm.validate()
                if(this.valid){
                  this.loading=true
                  //let fromTimeStamp=this.fromTimeStamp
                  //let toTimeStamp=this.toTimeStamp
                  let payload={
                   from_timestamp:this.fromTimeStamp,
                   to_timestamp:this.toTimeStamp
        }
        //console.log(payload,"payload")
        //console.log(fromTimeStamp)
        axios.post(this.$store.state.api+'getTriggerLogsBetweenTimestamp',payload,{headers: {
          Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
        //   console.log("Param data received")
          //console.log(response.data.monthlyData)
          //context.state.monthlyData=response.data.monthlyData
 // console.log(response.data.data,"dddd")
    //console.log('something')
    
    
if (response.data.status === 'success') {
    let data = [];
    let index = 0;

    for (let i of response.data.data) {
      i['from_Timestamp'] = moment(`${this.form.from_date} ${this.form.fromTime}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
i['to_Timestamp'] = moment(`${this.form.to_date} ${this.form.toTime}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");

      i['index'] = index++;
        data.push(i);
    }

    this.logs=data
          //console.log(response.data.data)
          
          if(this.logs.length==0){
              this.info = "No data to show";
                this.showDismissibleAlert = true;
          }
        }else{
          this.info = response.data.msg;
          //this.info = d;
                this.showDismissibleAlert = true;
          //console.log(response.data.msg)
          //this.loading=false
        }
          this.loading=false
    
        })
        .catch(err=>{
        this.showDismissibleAlert=true
                this.info=err
                this.loading=false
                })
    
        
        
        
        
                }
            }
        
        },
    }
    </script>